/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "new-unitron-platform-offers-advances-in-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#new-unitron-platform-offers-advances-in-technology",
    "aria-label": "new unitron platform offers advances in technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "New Unitron Platform Offers Advances in Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Unitron Discover platform introduces updates to their sophisticated digital processing system for ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ". You may experience this platform in either the Moxi Jump R or the Moxi Fit models. Both of these hearing aids are small, discreet receiver-in-the-ear models available in a variety of fashionable colors. The Jump R features a rechargeable battery; the Fit utilizes a size 312 battery."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Engineers designing hearing aids must balance speech understanding, spatial awareness, and comfort. In the past, sometimes a particular processing strategy designed to solve one problem would make sacrifices in other areas of the listening experience. For example, technology designed to reduce noise and improve speech understanding might make it more difficult to localize sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron’s SoundCore encompasses four technologies that aim to give you the best listening experience possible with the fewest compromises: SoundNav 3.0, Speech Pro, Spatial Awareness, and Sound Conductor."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "SoundNav 3.0 accurately classifies that sounds that you are hearing into seven environments. Based on the classification, the hearing aids adjust their processing strategy. You need a different hearing mode when you’re at home reading the newspaper in quiet as opposed to walking in a crowded shopping mall. These hearing aids perform the fast calculations needed to make the necessary shifts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Speech Pro assists when you need to listen in noisy environments. For example, when you are in a restaurant with a group of friends, Speech Pro acts to dampen noise and make speech more easily heard. People with hearing loss need extra help in noisy environments because they lose some of the natural abilities of the unimpaired auditory system to filter out sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Spatial Awareness uses the microphones on both of your hearing aids together to accurately classify where sounds are coming from. This technology adjusts the frequency responses of your hearing aids so that you are better able to localize sounds in your environment. Other hearing aids can alter the sounds that you hear such that it becomes more difficult to localize sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sound Conductor works with the other technologies to find the right balance among the directionality, speech enhancement, and noise reduction systems in your hearing aids. The hearing aids are constantly working to give you effective and comfortable hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "unitrons-hearing-aids-fit-your-lifestyle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitrons-hearing-aids-fit-your-lifestyle",
    "aria-label": "unitrons hearing aids fit your lifestyle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron’s Hearing Aids Fit Your Lifestyle"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re a weekend warrior who hits the backcountry trails or the ski slopes, you’ll be happy that your Unitron hearing aids have the IP68 rating. This rating means that your hearing aids are built to resist dust and water."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Discover hearing aids connect to ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth devices"), ", allowing you to stream phone calls, music, and other audio. This direct connection reduces interference in the sound as it travels into your ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s also an app available to allow you to discretely control the hearing aids on your smart phone. You don’t have to reach up to your hearing aids to fiddle with a knob. However, if you’ve left your phone at home, you can still control your hearing aids with a push button."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can rate your hearing in your different listening environments. The hearing aids also continuously log data throughout the day. This gives your hearing care professional valuable information for your fine-tuning appointments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you enjoy listening to music, you’ll be glad to know that Discover hearing aids contain special technology designed to make music sound more natural. Sometimes hearing aid processing strategies that focus on speech comprehension can distort musical tones. Discover hearing aids are designed to recognize music and respond accordingly."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "discover-the-hearcom-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#discover-the-hearcom-experience",
    "aria-label": "discover the hearcom experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Discover the hear.com Experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people find shopping for hearing aids confusing. You want to make the right decisions for your hearing healthcare."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sign up for a 45 day risk-free trial through our website at hear.com and one of our hearing experts will tell you all about hearing loss and the latest hearing aids for you to try. We understand the technology and can explain things to you in jargon-free language."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you feel comfortable, we will set you up with an appointment for a hearing evaluation with a Partner Provider in your area. The hearing care professional will recommend the right hearing aids for you and fit them to meet your exact needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During the entire process, your expert will follow your progress and make sure that your experience meets your expectations. We even help you get financing for your hearing aids. If you are not satisfied, you can return the hearing aids within 45 days for a full refund."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Unitron Discover now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
